import './wind.config';
import React, { useEffect } from 'react';
import { LogBox, Platform } from 'react-native';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import * as SplashScreen from 'expo-splash-screen';
import { AnimatedAppLoader } from './src/components/AnimateAppLoader';
import { MainStack } from './src/navigation/main.stack';
import { LocalizationProvider } from './src/locale';
import { StorageProviderProvider } from './src/context/StorageProviderContext/StorageProviderContext';
import { ErrorHandlerProvider } from './src/context/ErrorHandlerContext/ErrorHandlerContext';
import { ApiProvider } from './src/context/ApiContext/ApiContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from './src/redux/utils';
import { store } from './src/redux/store';
import { LoaderProvider } from './src/context/Loader/loader.context';
import { connectToDevTools } from 'react-devtools-core';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { s } from 'react-native-wind';
import { StatusBar } from 'expo-status-bar';
import { GlobalStyleProvider } from './src/portability/providers/GlobalStyleProvider/GlobalStyleProvider';
import { BadgeProgressProvider } from './src/context/BadgeProgressContext/BadgeProgressContext';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import LinkingConfig from './src/navigation/core/linkingConfig';
import * as Analytics from './src/portability/services/Firebase/Analytics/Analytics';
import * as Crashlytics from './src/portability/services/Firebase/Crashlytics/Crashlytics';
import { OneSignal } from 'react-native-onesignal';
import Constants from 'expo-constants';
// Import tracking transparency only on iOS
const ExpoTrackingTransparency =
  Platform.OS === 'ios'
    ? require('expo-tracking-transparency')
    : { requestTrackingPermissionsAsync: async () => ({ status: 'denied' }) };

if (__DEV__) {
  connectToDevTools({
    host: 'localhost',
    port: 8097,
  });
}

LogBox.ignoreLogs(['Setting a timer']);

SplashScreen.preventAutoHideAsync().catch(() => {
  /* reloading the app might trigger some race conditions, ignore them */
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000 * 5,
      retry: false,
    },
  },
});

const UmmyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#fff',
  },
};

export default function App() {
  useEffect(() => {
    (async () => {
      // init the app preventing analytics and crashlytics
      await Analytics.stop();
      await Crashlytics.stop();

      OneSignal.initialize(Constants.expoConfig!.extra?.oneSignalAppId);

      // check if the user has granted permissions (only on iOS)
      const { status } =
        await ExpoTrackingTransparency.requestTrackingPermissionsAsync();
      if (status === 'granted') {
        await Analytics.init();
        await Crashlytics.init();
      } else {
        await Analytics.stop();
        await Crashlytics.stop();
      }
    })();
  }, []);

  return (
    <Provider store={store}>
      <StorageProviderProvider>
        <ErrorHandlerProvider>
          <ApiProvider>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider>
                <SafeAreaProvider>
                  <AnimatedAppLoader>
                    <GestureHandlerRootView style={s`flex-1`}>
                      <LoaderProvider>
                        <NavigationContainer
                          theme={UmmyTheme}
                          documentTitle={{
                            enabled: true,
                            formatter: (_, route) => route?.name || 'Qomprendo',
                          }}
                          linking={LinkingConfig}
                        >
                          <BadgeProgressProvider>
                            <GlobalStyleProvider>
                              <MainStack />
                            </GlobalStyleProvider>
                          </BadgeProgressProvider>
                        </NavigationContainer>
                        <StatusBar style="dark" />
                      </LoaderProvider>
                    </GestureHandlerRootView>
                  </AnimatedAppLoader>
                </SafeAreaProvider>
              </LocalizationProvider>
            </QueryClientProvider>
          </ApiProvider>
        </ErrorHandlerProvider>
      </StorageProviderProvider>
    </Provider>
  );
}
