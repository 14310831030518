import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { Config } from '../../config';
import { isSomethingLoading } from '../../redux/general/general.selectors';
import { useSelector } from '../../redux/utils';

export type OpenCallback = () => void;
export type CloseCallback = () => void;

export type LoaderProps = {
  open: OpenCallback;
  close: CloseCallback;
};

export const LoaderContext = createContext<LoaderProps>({
  open: () => {
    throw new Error('No open method assigned to the provider');
  },
  close: () => {
    throw new Error('No close method assigned to the provider');
  },
});

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isFullPageLoaderLoading = useSelector(isSomethingLoading);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);
  const viewAnimation = useRef<Animatable.View & View>(null);

  const open = useCallback(() => {
    setIsLoading(true);
  }, []);

  const close = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const animation = async () => {
      if (isLoading || isFullPageLoaderLoading) {
        setVisible(true);
        if (viewAnimation.current) {
          await viewAnimation.current.fadeIn?.(300);
        }
      } else {
        if (viewAnimation.current) await viewAnimation.current.fadeOut?.(300);
        setVisible(false);
      }
    };

    animation();
  }, [isFullPageLoaderLoading, isLoading]);

  return (
    <LoaderContext.Provider
      value={{
        open,
        close,
      }}
    >
      {children}
      {isVisible ? (
        <Animatable.View ref={viewAnimation} style={styles.loaderWrapper}>
          <ActivityIndicator size="large" color={Config.colorPrimary} />
        </Animatable.View>
      ) : null}
    </LoaderContext.Provider>
  );
};

const styles = StyleSheet.create({
  loaderWrapper: {
    position: 'absolute',
    justifyContent: 'center',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
});
