import * as React from 'react';
import { BadgeProgress } from '../../api/feedback/feedback.models';
import { createNotImplementedMethod } from '../../api/utils';
import {
  Button,
  ButtonType,
  Description,
  Subtitle,
  UmModal,
} from '../../components';
import { useLanguage } from '../../locale';
import { s } from 'react-native-wind';
import { getBadgeImageFromId } from '../../utils/getBadgeImageFromId';
import { extractBadgeIdFromPath } from '../../utils/extractBadgeIdFromPath';
import { Dimensions, Image, View } from 'react-native';
import { default as ConfettiCannon } from 'react-native-confetti-cannon';

export type BadgeProgressContextProps = {
  badgeProgress: BadgeProgress | undefined;
  setBadgeProgress: (badgeProgress: BadgeProgress) => void;
};

export const BadgeProgressContext =
  React.createContext<BadgeProgressContextProps>({
    badgeProgress: undefined,
    setBadgeProgress: createNotImplementedMethod(),
  });

export const BadgeProgressProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [t] = useLanguage();

  const confettiCannonRef1 = React.useRef<ConfettiCannon>(null);
  const confettiCannonRef2 = React.useRef<ConfettiCannon>(null);
  const confettiCannonRef3 = React.useRef<ConfettiCannon>(null);
  const confettiCannonRef4 = React.useRef<ConfettiCannon>(null);

  const [badgeProgress, setBadgeProgress] = React.useState<BadgeProgress>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const isBadgeCompleted = React.useMemo(() => {
    return badgeProgress?.to.minProgress === 100;
  }, [badgeProgress?.to.minProgress]);

  const badgeImage = React.useMemo(() => {
    if (badgeProgress) {
      const imageId = extractBadgeIdFromPath(badgeProgress.to.image);
      return getBadgeImageFromId(imageId);
    }
  }, [badgeProgress]);

  const cannons = React.useMemo(() => {
    return (
      <>
        <ConfettiCannon
          ref={confettiCannonRef1}
          count={25}
          origin={{ x: -20, y: -20 }}
          autoStart={false}
          explosionSpeed={500}
          fadeOut={true}
        />
        <ConfettiCannon
          ref={confettiCannonRef2}
          count={25}
          origin={{ x: Dimensions.get('window').width + 20, y: -20 }}
          autoStart={false}
          explosionSpeed={500}
          fadeOut={true}
        />
        <ConfettiCannon
          ref={confettiCannonRef3}
          count={25}
          origin={{ x: -20, y: -20 }}
          autoStart={false}
          explosionSpeed={500}
          fadeOut={true}
        />
        <ConfettiCannon
          ref={confettiCannonRef4}
          count={25}
          origin={{ x: Dimensions.get('window').width + 20, y: -20 }}
          autoStart={false}
          explosionSpeed={500}
          fadeOut={true}
        />
      </>
    );
  }, []);

  React.useEffect(() => {
    if (
      badgeProgress &&
      badgeProgress.from.minProgress < badgeProgress.to.minProgress
    ) {
      setIsOpen(true);
      const timeoutRef1 = setTimeout(() => {
        confettiCannonRef1.current?.start();
        confettiCannonRef2.current?.start();
      }, 1 * 1000);
      const timeoutRef2 = setTimeout(() => {
        confettiCannonRef3.current?.start();
        confettiCannonRef4.current?.start();
      }, 1 * 1750);

      return () => {
        clearTimeout(timeoutRef1);
        clearTimeout(timeoutRef2);
      };
    }
  }, [badgeProgress]);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <BadgeProgressContext.Provider value={{ badgeProgress, setBadgeProgress }}>
      {children}
      <UmModal isOpen={isOpen} outerComponent={cannons}>
        <Subtitle>{t('gamification.feedbackModal.title')}</Subtitle>
        <Description style={s`mt-2`}>
          {isBadgeCompleted
            ? t('gamification.feedbackModal.descriptionCompleted')
            : t('gamification.feedbackModal.descriptionNotCompleted')}
        </Description>
        <Image style={s`w-full h-56 resize-contain`} source={badgeImage} />
        <View style={s`flex-row`}>
          <Button
            containerStyle={s` flex-1`}
            buttonType={ButtonType.Tertiary}
            onPress={closeModal}
          >
            {t('gamification.feedbackModal.close')}
          </Button>
        </View>
      </UmModal>
    </BadgeProgressContext.Provider>
  );
};
