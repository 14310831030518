import { RefreshAuthDelegate } from '../services/HttpClient/IHttpClient';
import isTokenExpired from './isTokenExpired';

export default async function refreshTokenIfNecessary({
  accessToken,
  refreshAuthDelegate,
  refreshToken,
}: {
  accessToken: string;
  refreshAuthDelegate?: RefreshAuthDelegate;
  refreshToken?: string;
}) {
  const isExpired = isTokenExpired(accessToken);
  if (refreshAuthDelegate && isExpired && refreshToken) {
    const res = await refreshAuthDelegate.refresh(refreshToken);
    const { AccessToken, RefreshToken } = res.AuthenticationResult;
    return { AccessToken, RefreshToken };
  } else {
    return { AccessToken: null, RefreshToken: null };
  }
}
