import React, { createContext, useMemo } from 'react';
import {
  IStorageProvider,
  NotImplementedStorageProvider,
} from '../../services/StorageProvider/IStorageProvider';
import { MobileStorageProvider } from '../../services/StorageProvider/StorageProvider';

export type StorageProviderContextProps = {
  storageProvider: IStorageProvider;
};

export const StorageProviderContext =
  createContext<StorageProviderContextProps>({
    storageProvider: new NotImplementedStorageProvider(),
  });

export const StorageProviderProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const storageProvider = useMemo(() => {
    return new MobileStorageProvider();
  }, []);

  return (
    <StorageProviderContext.Provider value={{ storageProvider }}>
      {children}
    </StorageProviderContext.Provider>
  );
};
