import './GlobalStyle.css';

import * as React from 'react';

export const GlobalStyleContext = React.createContext(null);

export const GlobalStyleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <GlobalStyleContext.Provider value={null}>
      {children}
    </GlobalStyleContext.Provider>
  );
};
