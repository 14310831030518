import * as React from 'react';
import { Path } from 'react-native-svg';
import { Config } from '../../../../config';

type LineProps = {
  line?: string;
};

const Line: React.FC<LineProps> = ({ line }) => {
  if (!line) return null;

  return (
    <Path
      key="line"
      d={line}
      strokeWidth={3}
      stroke={Config.colorPrimary}
      fill="none"
    />
  );
};

export default Line;
