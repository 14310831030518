import * as React from 'react';
import { Circle, NumberProp } from 'react-native-svg';
import { Config } from '../../../../config';
import { LineChartDataExtended } from '../../stats.models';

type DecoratorProps = {
  x?: (i: number) => NumberProp;
  y?: (i: number) => NumberProp;
  data?: LineChartDataExtended[];
};

const Decorator: React.FC<DecoratorProps> = ({ data, x, y }) => {
  // Don't render if required props are missing
  if (!data || !x || !y) return null;

  return (
    <>
      {data.map((value, index) => {
        return value.wasNull ? null : (
          <Circle
            key={`decorator-circle-${index}`}
            cx={x(index)}
            cy={y(value.value)}
            r={4}
            stroke={Config.colorPrimary}
            strokeWidth={3}
            fill={'white'}
          />
        );
      })}
    </>
  );
};

export default Decorator;
