import {
  PaginatedRequest,
  PaginatedResponse,
} from '../../models/general.models';

export enum VotingDay {
  Monday = 'MON',
  Tuesday = 'TUE',
  Wednesday = 'WED',
  Thursday = 'THU',
  Friday = 'FRI',
  Saturday = 'SAT',
  Sunday = 'SUN',
}

export enum TeamStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export interface TeamUser {
  userEmail: string;
  createAt: string;
  userId: string;
  updatedAt: string;
  username: string;
  isAdmin: boolean;
  votingDays: VotingDay[];
  notificationTime: string;
  notificationEnabled: boolean;
  useDefaultVotingDays: boolean;
  notificationEmailEnabled?: boolean;
}

export interface Team {
  id: string;
  name: string;
  admins: string[];
  membersCount: number;
  companyId: string;
  voteAnonymous: boolean;
  status: TeamStatus;
  votingDays: VotingDay[];
  notificationTime: string;
}

export interface MyTeam extends Team {
  meta: TeamUser;
}

export interface ResponseTeam {
  id: string;
  name: string;
  admins: string[];
  companyId: string;
  membersCount: number;
  statusFilter: string;
  voteAnonymous: 0 | 1;
  votingDays: VotingDay[];
  notificationTime: string;
}

export interface ResponseMyTeam extends ResponseTeam {
  meta: TeamUser;
}

export interface GetMyTeamsRequest {
  status: TeamStatus;
}

export interface GetMyTeamsResponse {
  items: ResponseMyTeam[];
}

export interface GetTeamDetailsRequest {
  teamId: string;
}

export interface AddMemberRequest {
  teamId: string;
  memberId: string;
}

export interface AddMemberResponse {
  item: TeamUser;
}

export interface RemoveTeamMemberRequest {
  teamId: string;
  memberId: string;
}

export interface GetTeamDetailsResponse {
  item: ResponseTeam;
}

export interface UpdateTeamRequest {
  teamId: string;
  name?: string;
  voteAnonymous?: boolean;
  votingDays?: VotingDay[];
  notificationTime?: string;
}

export interface UpdateTeamResponse {
  item: Team;
}

export interface UpdateTeamStatusRequest {
  teamId: string;
  status: TeamStatus;
}

export interface GetTeamMembersRequest extends PaginatedRequest {
  teamId: string;
}

export type GetTeamMembersResponse = PaginatedResponse<TeamUser>;

export interface UpdateTeamMemberMetaRequest {
  teamId: string;
  memberId: string;
  workingDays?: VotingDay[];
  notificationTime?: string;
  notificationEnabled?: boolean;
  useDefaultVotingDays?: boolean;
  notificationEmailEnabled?: boolean;
}

export interface GetTeamMemberRequest {
  teamId: string;
  memberId: string;
}

export interface GetTeamMemberResponse {
  item: TeamUser;
}
