export default {
  timePicker: {
    title: 'Choose a time',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  bottomTab: {
    teams: 'Teams',
    profile: 'Profile',
    progress: 'Progress',
    stats: 'Stats',
    feedback: 'Feedback',
    teamSettings: 'Team Management',
  },
  authentication: {
    title: 'Hello, sign in to get started!',
    description:
      'Join us and start tracking your daily mood, or use it with your work team!',
    email: 'Email',
    acceptTerms1: 'I declare to accept ',
    acceptTerms2: ' and ',
    acceptTerms3: ' of Qomprendo',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    emailError: 'The entered email is not valid',
    passwordlessExplanation:
      "We will send you a code to verify your identity, enter it and you're in!",
    cta: 'Sign in',
    emailNotFound: 'Email not found',
    emailNotFoundDescription:
      'You should be registered but you are not? Contact support at support@qomprendo.com',
  },
  onboarding: {
    privacy: {
      title: 'Privacy, terms and conditions',
      description:
        'In order to continue, you need to accept the privacy policy and finally the terms and conditions',
      confirm: 'Confirm',
    },
    name: {
      title: 'Your name',
      description:
        'Enter the name that will be displayed by other people who use Qomprendo.',
      name: 'Name',
      next: 'Next',
    },
    birthDate: {
      title: 'Your birth date',
      description:
        'The birth date is important to better understand your real mood',
      birthDate: 'Birth date',
      confirm: 'Confirm',
      cancel: 'Cancel',
      next: 'Next',
      skip: 'Skip',
    },
    anonymousVote: {
      title: 'Feedback visibility',
      description1:
        "At Qomprendo, we care a lot about your privacy, that's why feedback is anonymous by default.",
      description3:
        'This setting is always available in your profile and you can change it whenever you want.',
      switchLabel: 'Enable named feedback',
      skip: 'Skip',
      finish: 'Done',
    },
  },
  intro: {
    welcome: 'Welcome!',
    feedbackTitle: 'The feedback you needed',
    feedbackDescription:
      'Share feedback about your day with your company, so it can improve and make the right decisions!',
    historyTitle: 'Remember the votes you gave',
    statsDescription:
      'Track your mood on each team and you will quickly understand the aspects that are going better and those to be improved!',
    gamificationTitle: 'Earn points and come first',
    rewardsDescription:
      'Join the human challenge by collecting as many points as you can, help your company reach all the goals and get a chance to win a prize!',
    enter: 'Enter',
  },
  authCode: {
    title: 'Enter the code',
    description: 'Enter the code we sent to %{email}.',
    resendCode: 'Resend the code',
    confirm: 'Confirm',
    pasteClipboard: 'Paste code from clipboard',
  },
  teamSettings: {
    roleDescription1: 'You are',
    roleDescription2: 'of the team.',
    administrator: 'administrator',
    teamMembers: 'Members',
    yourNotifications: 'Notifications',
    teamNotifications: 'Team standard notifications',
    enable: 'Enable',
    personalizeReminder: 'Personalize notifications',
    emptyDescription: 'There are no teams to manage yet.',
    at: 'At:',
  },
  teamMembers: {
    search: 'Search...',
    title: 'Team members',
  },
  manageUser: {
    title: 'Manage user',
    handledUser: 'You are managing:',
    notifications: 'Notifications',
    enable: 'Enable',
    personalizeReminder: 'Personalize notifications',
    at: 'At:',
    removeFromTeam: 'Remove from team',
  },
  userNotification: {
    title: 'Notifications',
    enablePush: 'Enable Push Reminder',
    enableEmail: 'Enable Email Reminder',
    at: 'At:',
    save: 'Save',
    cancel: 'Cancel',
  },
  profile: {
    title: 'Profile',
    details: 'Details',
    changeImage: 'Change profile image',
    dateOfBirth: 'Date of birth',
    username: 'Username',
    email: 'Email',
    signOut: 'Logout',
    requestDelete: 'Request deletion',
    editProfileImage: 'Change profile image',
    settings: 'Settings',
    anonymousVoteLabel: 'Enable anonymous feedback',
    harmoniaActiveLabel: "Enable Harmonia's advice",
    deleteModal: {
      title: 'Do you confirm deletion?',
      description:
        'Your request will be processed and your account will be deleted within 14 days of the request. Once the account is deleted, you will no longer be able to access and you will have to register again to interact with the application. Are you still sure you want to delete your profile?',
      cancel: 'Cancel',
      delete: 'Delete',
    },
    confirmModal: {
      title: 'Deletion requested',
      description:
        "We're sorry to see you go... Within 14 days your profile will be deleted and access to your private area will no longer be possible. Thank you for giving us a chance and we hope to see you again in the future!",
      ok: 'Ok',
    },
  },
  editProfile: {
    title: 'Edit profile',
    description:
      'In this section you can edit your profile, at the moment only the username can be changed.',
    usernamePlaceholder: 'Username',
    usernameError: 'The username must contain at least 3 characters',
    save: 'Save profile',
  },
  gamification: {
    feedbackModal: {
      title: 'Congratulations!',
      descriptionNotCompleted:
        "Your digital tree has grown! Keep it up and it'll be big in no time!",
      descriptionCompleted:
        'Fantastic! Your digital tree has grown big just because of you, from tomorrow you will have another one to grow. Keep it up!',
      close: 'Close',
    },
    myBadge: 'My badges',
    section: 'Section',
    personal: 'Personal',
    company: 'Company',
    companySection: {
      allProgresses: 'Milestones',
      daysLeft: 'Days remaining',
      challengeExpired: 'Challenge expired',
      nextPrice: 'Next prize: %{currentPoints} / %{targetPoints}',
      prizes: 'Prizes',
      results: 'Results so far',
      unlockAllPrizes:
        'Your company has unlocked all the prizes, congratulations!',
      quantity: 'Quantity',
      noData: 'There is currently no challenge in your company.',
    },
  },
  leaderboard: {
    emptyMessage:
      "There's just a happy cat here, but no one in the leaderboard yet.",
    yourPosition: 'Your position',
    leaderboard: 'Leaderboard',
    firstArrived:
      '🥇🏆 Congratulations! This month you came in 1st! Keep it up next month too!',
    secondArrived:
      '🥈🏆 Congratulations! This month you came in 2nd! You were very close to the first position!',
    thirdArrived:
      '🥉🏆 Congratulations! This month you came in 3rd! You were very close to the first position!',
    parteciped:
      'Thank you for participating in the competition! Continue to take care of your well-being in the company.',
    helpBottomSheet: {
      title: 'How does it work?',
      pointDescription:
        'Points are awarded for each feedback left on a team, the total points are updated at the end of the day. With a feedback you get 100 points, adding also a comment 150.',
      rewardDescription:
        "If at the end of the month at least 80% of the company's feedback has been given, Qomprendo will commit to planting trees in its name.",
      close: 'Close',
    },
  },
  stats: {
    summary: 'Summary',
    daysInRow: 'Consistency',
    humorChart: 'Mood graph',
    moodsCount: 'Mood count',
    commentSentiment: 'Comments analysis',
    moodCalendar: 'Your mood calendar',
    wellnessIndex: 'Wellness index',
    feedbackCalendar: 'Your feedback calendar',
    streak: 'Longest weekly streak: ',
    forgetFeedback1: 'You forgot', // TODO: Remove
    forgetFeedback2: 'times to give feedback this week', // TODO: Remove
    feedbackCountDescription1:
      'Hey %{name}, you have expressed %{feedbackCount} feedbacks in the last 14 days,',
    feedbackCountDescription2: {
      lowerThanTwentyPercent:
        'it must be an intense period, take care of your well-being, using Qomprendo can make a difference!',
      fromTwentyToForty: 'tell me how your day went today.',
      fromFortyToSixty:
        'you started off on the right foot, tell me more often how you feel!',
      fromSixtyToEighty:
        'great job! You are making a difference for your well-being!',
      fromEightyToHundred: 'You are close to the perfect week, keep it up!',
      hundred: 'WOW! You have reached the perfect week, keep it up!',
    },
    emptySentiment:
      'We do not have enough data to understand the prevailing sentiment of the week.',
    rememberComment:
      'Always remember to comment, so as to always have this section enabled.',
    sentimentsComments:
      'The feelings that have emerged from your comments are mainly:',
    positive: 'Positive',
    negative: 'Critical',
    neutral: 'Neutral',
    mixed: 'Contrasting',
    warning: 'Warning',
    sentimentWarning1: 'This week you left a comment only the',
    sentimentWarning2: 'times.',
    sentimentWarning3:
      'Protect your well-being, tell me how you feel more often.',
    feelGood: 'Positive aspects',
    feelGoodDescription:
      'These are the areas that are contributing to your work well-being',
    feelBad: 'Aspects to improve',
    feelBadDescription:
      'These are the areas to improve for your work well-being',
    notEnoughtData1:
      'We do not have enough data to provide you with an accurate statistic of the week.',
    notEnoughtData2: 'Come back when you have given more feedback.',
    feedbackDetails: {
      teamLabel: 'View feedbacks for team',
    },
  },
  changeTeamBottomSheet: {
    title: 'Select a team',
    others: 'Or you can see:',
    cancel: 'Cancel',
    confirm: 'Confirm',
    summary: 'Summary',
  },
  changeCompanyBottomSheet: {
    title: 'Select a company',
    cancel: 'Cancel',
    confirm: 'Confirm',
    summary: 'Summary',
  },
  feedback: {
    title: 'How was your day?',
    alreadyVoted: 'Teams you have already given your feedback',
    anonymous: 'Visibility: Anonymous',
    named: 'Visibility: Named',
    leaveComment: 'Leave a comment',
    notEnoughtData: "You can't respond because you are not yet part of a team.",
    commentPlaceholder: 'Describe your day in a few words...',
    feedbackSelectionTypeTitle: 'Which aspects influenced your day?',
    goodAreasFeedback: 'Which aspects went well?',
    badAreasFeedback: 'Which aspects need to improve?',
    feedbackVisibility: 'Feedback visibility',
    bottomBar: {
      points: 'Points:',
      on: 'on',
      finish: 'Done',
    },
  },
  harmonia: {
    emptyMessage:
      "Hello, I'm Harmonia, how was your day? Let's talk about it together, I can help you make it better!",
    notViewed: 'New messages',
    inputPlaceholder: 'Write a message...',
    message1: "I'm trying to remember your name...",
    message2: "I'm interpreting your feedback...",
    message3: 'I am thinking about the best answer to give you...',
    message4: "I don't know, maybe this way is better...",
    message5: 'Give me just another moment...',
    connectionError: 'Reconnection failed, try entering this screen again',
  },
  slack: {
    requestScreen: {
      title: 'Add Qomprendo to your Slack workspace',
      paragraph:
        'Proceed to authorize Qomprendo to communicate with you via Slack',
      cta: 'Continue with Slack',
    },
    errorFallback: {
      title: 'An error occurred',
      cta: 'Try again',
    },
    successFallback: {
      title:
        'Congratulations! You have just added Qomprendo to your Slack workspace',
      cta: 'Back to homepage',
    },
  },
  allChallenges: {
    allResults: 'All obtained results',
    previousChallenges: 'Past competitions',
    range: 'Period',
    prize: 'Prize',
    quantity: 'Quantity',
  },
  shared: {
    selectAppLanguage: {
      label: 'Selected language',
      modalTitle: 'Change language',
      modalDescription:
        'Select the language you want to use in the application',
      save: 'Save',
      cancel: 'Cancel',
    },
    languages: {
      it: 'Italiano',
      en: 'English',
      de: 'Deutsch',
      es: 'Español',
    },
  },
  remoteConfig: {
    versionControl: {
      title: 'New version available',
      description:
        'A new version of Ummy is available, update now to continue using the app.',
      update: 'Update',
    },
    genericError: {
      title: 'The app is not available at the moment',
      message:
        'It seems that the app is not available at the moment, try again later.',
    },
    networkError: {
      title: 'There are problems with your network',
      description:
        'We have encountered problems with your internet network and this does not allow you to use Qomprendo, please try again later or when you have a more stable connection',
      ctaLabel: 'Retry',
    },
  },
};
