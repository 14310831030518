import * as React from 'react';
import {
  useGetHarmoniaConfig,
  useGetUserThread,
  useSetMessageShown,
} from '../../../hooks/api/harmonia.api';
import useUserData from '../../../hooks/useUserData';
import { useQueryClient } from '@tanstack/react-query';
import { InvalidationTag } from '../../../hooks/api/utils';
import {
  HarmoniaMessageAuthor,
  UserHarmoniaMessage,
} from '../../../api/harmonia/harmonia.models';
import { Config } from '../../../config';
import useWebSocket from 'react-use-websocket';
import { useStorageProvider } from '../../../context/StorageProviderContext/useStorageProvider';
import { StorageKeys } from '../../../services/StorageProvider/IStorageProvider';
import dayjs from 'dayjs';
import { useErrorHandler } from '../../../context/ErrorHandlerContext/useErrorHandler';
import { useLanguage } from '../../../locale';
import { useApi } from '../../../context/ApiContext/useApi';
import refreshTokenIfNecessary from '../../../utils/refreshTokenIfNecessary';

export const useHarmoniaAssistant = () => {
  const queryClient = useQueryClient();
  const { company } = useUserData();
  const storage = useStorageProvider();
  const errorHandler = useErrorHandler();
  const { authApi } = useApi();
  const [t] = useLanguage();
  const [isWaitingMessage, setIsWaitingMessage] = React.useState(false);
  const [shouldScrollToEnd, setShouldScrollToEnd] = React.useState(false);
  const [isListEmpty, setIsListEmpty] = React.useState(false);

  const [messagesToShow, setMessagesToShow] = React.useState<
    UserHarmoniaMessage[]
  >([]);
  const [newMessageText, setNewMessageText] = React.useState('');

  const getUrlString = React.useCallback(async () => {
    const currentAccessToken = await storage.getItem(
      StorageKeys.ACCESS_TOKEN_KEY
    );
    const currentRefreshToken = await storage.getItem(
      StorageKeys.REFRESH_TOKEN_KEY
    );
    await refreshTokenIfNecessary({
      accessToken: currentAccessToken!,
      refreshAuthDelegate: authApi,
      refreshToken: currentRefreshToken!,
    });
    const connectionToken = await storage.getItem(StorageKeys.ACCESS_TOKEN_KEY);

    return `${Config.harmoniaWsUrl}?Auth=${connectionToken}`;
  }, [authApi, storage]);

  const { lastMessage, sendMessage } = useWebSocket(
    getUrlString,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 3,
      onReconnectStop: () => {
        errorHandler.blockInteraction(t('harmonia.connectionError'));
      },
    },
    true
  );

  const { mutateAsync: setMessagesAsRead } = useSetMessageShown();
  const { data: harmoniaConfig } = useGetHarmoniaConfig(company?.id);
  const {
    data: getUserThreadResponse,
    fetchNextPage: getPrevMessages,
    isFetching,
    isFetchingNextPage,
  } = useGetUserThread(
    { companyId: company?.id },
    !!company && !!harmoniaConfig
  );

  React.useEffect(() => {
    if (company) {
      return () => {
        queryClient.setQueryData(
          [InvalidationTag.HarmoniaMessages, { companyId: company!.id }],
          () => {
            return {
              pageParams: ['nothing'],
              pages: [],
            };
          }
        );
      };
    }
  }, [company, queryClient]);

  React.useEffect(() => {
    return () => {
      setMessagesToShow([]);
      queryClient.invalidateQueries({
        queryKey: [InvalidationTag.HarmoniaMessages],
      });
      queryClient.invalidateQueries({
        queryKey: [InvalidationTag.HarmoniaConfig],
      });
    };
  }, [queryClient]);

  React.useEffect(() => {
    if (company) {
      setMessagesAsRead({ companyId: company?.id });
    }
  }, [company, setMessagesAsRead]);

  React.useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);
      if (data.error) {
        setMessagesToShow((pv) => {
          return [
            {
              author: HarmoniaMessageAuthor.assistant,
              createdAt: dayjs().toISOString(),
              message:
                data.message.text ||
                'Internal assistant error. Please, send a new message',
              viewedAt: dayjs().toISOString(),
            },
            ...pv,
          ];
        });
      } else {
        setMessagesToShow((pv) => {
          return [data.message, ...pv];
        });
      }
      setShouldScrollToEnd(true);
    }
  }, [lastMessage]);

  React.useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (messagesToShow.length > 0 && harmoniaConfig) {
      const lastMessage = messagesToShow[0];
      const isLastMessageFromUser =
        lastMessage.author === HarmoniaMessageAuthor.user;
      const timeDiffInMs = dayjs().diff(
        dayjs(lastMessage.createdAt),
        'milliseconds'
      );
      const isMessageOlderThanMax =
        timeDiffInMs < harmoniaConfig.responseTimeout * 1000;
      if (isLastMessageFromUser && isMessageOlderThanMax) {
        setIsWaitingMessage(true);
        timeoutRef = setTimeout(() => {
          setIsWaitingMessage(false);
        }, harmoniaConfig.responseTimeout * 1000 - timeDiffInMs);
      }
    }

    return () => {
      if (timeoutRef) {
        setIsWaitingMessage(false);
        clearTimeout(timeoutRef);
      }
    };
  }, [harmoniaConfig, messagesToShow]);

  React.useEffect(() => {
    if (
      getUserThreadResponse &&
      getUserThreadResponse?.pages.length > 0 &&
      getUserThreadResponse?.pages[getUserThreadResponse.pages.length - 1].items
        .length > 0
    ) {
      const history = [
        ...getUserThreadResponse.pages[getUserThreadResponse.pages.length - 1]
          .items,
      ];
      setMessagesToShow((pv) => {
        return [...pv, ...history];
      });
    }
  }, [getUserThreadResponse]);

  React.useEffect(() => {
    setIsListEmpty(
      !isFetching &&
        !!getUserThreadResponse &&
        getUserThreadResponse.pages.flatMap((p) => p.items).length === 0
    );
  }, [getUserThreadResponse, isFetching]);

  const sendHarmoniaMessage = React.useCallback(() => {
    if (company && newMessageText) {
      try {
        sendMessage(
          JSON.stringify({
            action: 'sendMessage',
            companyId: company.id,
            message: newMessageText,
          })
        );
        setMessagesToShow((pv) => [
          {
            author: HarmoniaMessageAuthor.user,
            createdAt: dayjs().toISOString(),
            message: newMessageText,
            viewedAt: dayjs().toISOString(),
          },
          ...pv,
        ]);
        setNewMessageText('');
        setShouldScrollToEnd(true);
      } catch (e) {}
    }
  }, [company, newMessageText, sendMessage]);

  const resetScrollToEnd = React.useCallback(() => {
    setShouldScrollToEnd(false);
  }, []);

  const loadPrevMessages = React.useCallback(() => {
    getPrevMessages();
  }, [getPrevMessages]);

  return {
    messagesToShow,
    loadPrevMessages,
    areMessagesLoading: isFetchingNextPage || isFetching,
    newMessageText,
    setNewMessageText,
    sendHarmoniaMessage,
    isWaitingMessage,
    isListEmpty,
    shouldScrollToEnd,
    resetScrollToEnd,
    unseenMessages: harmoniaConfig?.unseenMessages,
  };
};
