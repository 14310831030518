import inst, { PluginFunc, ManipulateType } from 'dayjs';

declare module 'dayjs' {
  interface Dayjs {
    ceil(unit: UnitType, amount: number): inst.Dayjs;
  }
}
const ceil: PluginFunc = (_, dayjsClass) => {
  dayjsClass.prototype.ceil = function (unit, amount) {
    return this.add(
      amount - (this.get(unit) % amount),
      unit as ManipulateType
    ).startOf(unit);
  };
};
export default ceil;
