export default {
  timePicker: {
    title: 'Wähle eine Zeit',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
  },
  days: {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
  },
  months: {
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
  },
  bottomTab: {
    teams: 'Teams',
    profile: 'Profil',
    progress: 'Fortschritte',
    stats: 'Statistiken',
    feedback: 'Feedback',
    teamSettings: 'Teamverwaltung',
  },
  authentication: {
    title: 'Hallo, melde dich an um zu beginnen!',
    description:
      'Schließe dich uns an und beginne damit, deine tägliche Stimmung zu verfolgen, oder nutze es mit deinem Arbeitsteam!',
    email: 'E-Mail',
    acceptTerms1: 'Ich erkläre mich einverstanden mit den ',
    acceptTerms2: ' und den ',
    acceptTerms3: ' von Qomprendo',
    termsAndConditions: 'Geschäftsbedingungen',
    privacyPolicy: 'Datenschutzbestimmungen',
    emailError: 'Die eingegebene E-Mail ist nicht gültig',
    passwordlessExplanation:
      'Wir senden dir einen Code zur Überprüfung deiner Identität, gib diesen ein und du bist drin!',
    cta: 'Anmelden',
    emailNotFound: 'E-Mail nicht gefunden',
    emailNotFoundDescription:
      'Du solltest registriert sein, aber nicht? Kontaktiere den Support bei support@qomprendo.com',
  },
  onboarding: {
    privacy: {
      title: 'Datenschutz, AGB und Bedingungen',
      description:
        'Um fortzufahren, musst du die Datenschutzrichtlinien und letztlich die Geschäftsbedingungen akzeptieren',
      confirm: 'Bestätigen',
    },
    name: {
      title: 'Dein Name',
      description:
        'Gib den Namen ein, der von anderen Benutzern von Qomprendo angezeigt wird.',
      name: 'Name',
      next: 'Weiter',
    },
    birthDate: {
      title: 'Dein Geburtsdatum',
      description:
        'Das Geburtsdatum ist wichtig, um deine tatsächliche Stimmung besser zu verstehen',
      birthDate: 'Geburtsdatum',
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
      next: 'Weiter',
      skip: 'Überspringen',
    },
    anonymousVote: {
      title: 'Feedbacksichtbarkeit',
      description1:
        'Bei Qomprendo legen wir großen Wert auf deine Privatsphäre, deshalb ist das Feedback standardmäßig anonym.',
      description3:
        'Diese Einstellung ist jederzeit in deinem Profil verfügbar und du kannst sie ändern, wann immer du möchtest.',
      switchLabel: 'Namentliches Feedback aktivieren',
      skip: 'Überspringen',
      finish: 'Fertig',
    },
  },
  intro: {
    welcome: 'Willkommen!',
    feedbackTitle: 'Das Feedback, das du gebraucht hast',
    feedbackDescription:
      'Teile das Feedback über deinen Tag mit deinem Unternehmen, damit es sich verbessern und die richtigen Entscheidungen treffen kann!',
    historyTitle: 'Erinnere dich an die Bewertungen, die du abgegeben hast',
    statsDescription:
      'Verfolge deine Stimmung in jedem Team und du wirst sofort erkennen, welche Aspekte gut laufen und welche verbessert werden müssen!',
    gamificationTitle: 'Sammle Punkte und werde Erster',
    rewardsDescription:
      'Nimm an der menschlichen Herausforderung teil, indem du so viele Punkte wie möglich sammelst, deinem Unternehmen hilfst, alle Ziele zu erreichen, und die Chance hast, einen Preis zu gewinnen!',
    enter: 'Anmelden',
  },
  authCode: {
    title: 'Gib den Code ein',
    description: 'Gib den Code ein, den wir an %{email} gesendet haben.',
    resendCode: 'Code erneut senden',
    confirm: 'Bestätigen',
    pasteClipboard: 'Code aus Zwischenablage einfügen',
  },
  teamSettings: {
    roleDescription1: 'Du bist',
    roleDescription2: 'im Team.',
    administrator: 'Administrator',
    teamMembers: 'Mitglieder',
    yourNotifications: 'Deine Benachrichtigungen',
    teamNotifications: 'Standard Team Benachrichtigungen',
    enable: 'Aktivieren',
    personalizeReminder: 'Benachrichtigungen anpassen',
    emptyDescription: 'Es gibt noch keine Teams zu verwalten.',
    at: 'Um:',
  },
  teamMembers: {
    search: 'Suche...',
    title: 'Teammitglieder',
  },
  manageUser: {
    title: 'Benutzer verwalten',
    handledUser: 'Du verwaltest:',
    notifications: 'Benachrichtigungen',
    enable: 'Aktivieren',
    personalizeReminder: 'Benachrichtigungen anpassen',
    at: 'Um:',
    removeFromTeam: 'Aus dem Team entfernen',
  },
  userNotification: {
    title: 'Benachrichtigungen',
    enablePush: 'Push-Erinnerungen aktivieren',
    enableEmail: 'E-Mail-Erinnerungen aktivieren',
    at: 'Um:',
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  profile: {
    title: 'Profil',
    details: 'Details',
    changeImage: 'Profilbild ändern',
    dateOfBirth: 'Geburtsdatum',
    username: 'Benutzername',
    email: 'E-Mail',
    signOut: 'Abmelden',
    requestDelete: 'Löschung anfordern',
    editProfileImage: 'Profilbild ändern',
    settings: 'Einstellungen',
    anonymousVoteLabel: 'Anonymes Feedback aktivieren',
    harmoniaActiveLabel: 'Harmonia Tipps aktivieren',
    deleteModal: {
      title: 'Löschung bestätigen?',
      description:
        'Dein Antrag wird bearbeitet und dein Konto wird innerhalb von maximal 14 Tagen nach Antrag gelöscht. Nach der Löschung des Kontos kannst du dich nicht mehr anmelden und musst dich erneut registrieren, um mit der Anwendung interagieren zu können. Bist du sicher, dass du dein Profil löschen möchtest?',
      cancel: 'Abbrechen',
      delete: 'Löschen',
    },
    confirmModal: {
      title: 'Löschung angefordert',
      description:
        'Es tut uns leid, dich gehen zu sehen... Innerhalb von 14 Tagen wird dein Profil gelöscht und der Zugang zu deinem privaten Bereich ist nicht mehr möglich. Wir danken dir, dass du uns eine Chance gegeben hast und hoffen, dich in Zukunft wiederzusehen!',
      ok: 'Ok',
    },
  },
  editProfile: {
    title: 'Profil bearbeiten',
    description:
      'In diesem Bereich kannst du dein Profil bearbeiten, derzeit kann nur der Benutzername geändert werden.',
    usernamePlaceholder: 'Benutzername',
    usernameError: 'Der Benutzername muss mindestens 3 Zeichen enthalten',
    save: 'Profil speichern',
  },
  gamification: {
    feedbackModal: {
      title: 'Glückwunsch!',
      descriptionNotCompleted:
        'Dein digitaler Baum ist gewachsen! Mach weiter so und er wird schnell groß werden!',
      descriptionCompleted:
        'Fantastisch! Dein digitaler Baum ist groß geworden, nur dank dir. Ab morgen hast du einen neuen zum Wachsen. Weiter so!',
      close: 'Schließen',
    },
    myBadge: 'Meine Abzeichen',
    section: 'Bereich',
    personal: 'Persönlich',
    company: 'Firma',
    companySection: {
      allProgresses: 'Erfolge',
      daysLeft: 'Verbleibende Tage',
      challengeExpired: 'Herausforderung abgelaufen',
      nextPrice: 'Nächster Preis: %{currentPoints} / %{targetPoints}',
      prizes: 'Preise',
      results: 'Bisherige Ergebnisse',
      unlockAllPrizes:
        'Dein Unternehmen hat alle Preise freigeschaltet, Glückwunsch!',
      quantity: 'Menge',
      noData: 'Derzeit gibt es keine Herausforderung in deinem Unternehmen.',
    },
  },
  leaderboard: {
    emptyMessage:
      'Hier ist nur eine glückliche Katze, aber noch niemand in der Rangliste.',
    yourPosition: 'Deine Position',
    leaderboard: 'Rangliste',
    firstArrived:
      '🥇🏆 Glückwunsch! Diesen Monat bist du auf dem 1. Platz! Mach so weiter auch im nächsten Monat!',
    secondArrived:
      '🥈🏆 Glückwunsch! Diesen Monat bist du auf dem 2. Platz! Es hat nur wenig zum ersten Platz gefehlt!',
    thirdArrived:
      '🥉🏆 Glückwunsch! Diesen Monat bist du auf dem 3. Platz! Es hat nur wenig zum ersten Platz gefehlt!',
    parteciped:
      'Danke für deine Teilnahme am Wettbewerb! Weiterhin viel Erfolg bei der Pflege deines Wohlbefindens im Unternehmen.',
    helpBottomSheet: {
      title: 'Wie funktioniert es?',
      pointDescription:
        'Für jedes Feedback, das zu einem Team abgegeben wird, werden Punkte vergeben, die am Ende des Tages aktualisiert werden. Mit einem Feedback erhältst du 100 Punkte, mit einem Kommentar zusätzlich 150.',
      rewardDescription:
        'Wenn am Ende des Monats mindestens 80% der Unternehmensfeedbacks abgegeben wurden, verpflichtet sich Qomprendo, Bäume in ihrem Namen zu pflanzen.',
      close: 'Schließen',
    },
  },
  stats: {
    summary: 'Zusammenfassung',
    daysInRow: 'Konstanz',
    humorChart: 'Stimmungsdiagramm',
    moodsCount: 'Stimmungszählung',
    commentSentiment: 'Kommentaranalyse',
    moodCalendar: 'Dein Stimmungskalender',
    wellnessIndex: 'Wohlbefindensindex',
    feedbackCalendar: 'Dein Feedback-Kalender',
    streak: 'Längste wöchentliche Serie: ',
    forgetFeedback1: 'Du hast vergessen', // TODO: Entfernen
    forgetFeedback2: 'mal diese Woche Feedback zu geben', // TODO: Entfernen
    feedbackCountDescription1:
      'Hey %{name}, du hast in den letzten 14 Tagen %{feedbackCount} Feedbacks gegeben,',
    feedbackCountDescription2: {
      lowerThanTwentyPercent:
        'es muss eine intensive Zeit sein, kümmere dich um dein Wohlbefinden, Qomprendo kann einen Unterschied machen!',
      fromTwentyToForty: 'erzähl mir, wie dein Tag heute war.',
      fromFortyToSixty:
        'du hast gut angefangen, erzähl mir öfter, wie du dich fühlst!',
      fromSixtyToEighty:
        'tolle Arbeit! Du machst einen Unterschied für dein Wohlbefinden!',
      fromEightyToHundred:
        'Du bist nahe an einer perfekten Woche, mach weiter so!',
      hundred: 'WOW! Du hast eine perfekte Woche erreicht, weiter so!',
    },
    emptySentiment:
      'Wir haben nicht genug Daten, um das vorherrschende Gefühl der Woche zu verstehen.',
    rememberComment:
      'Denke immer daran zu kommentieren, damit dieser Abschnitt immer aktiviert bleibt.',
    sentimentsComments:
      'Die Gefühle, die aus deinen Kommentaren hervorgegangen sind, sind überwiegend:',
    positive: 'Positiv',
    negative: 'Kritisch',
    neutral: 'Neutral',
    mixed: 'Gemischt',
    warning: 'Warnung',
    sentimentWarning1: 'Diese Woche hast du nur',
    sentimentWarning2: 'mal kommentiert.',
    sentimentWarning3:
      'Schütze dein Wohlbefinden, erzähle mir öfter, wie du dich fühlst.',
    feelGood: 'Positive Aspekte',
    feelGoodDescription:
      'Das sind die Bereiche, die zu deinem Arbeitswohlbefinden beitragen',
    feelBad: 'Zu verbessernde Aspekte',
    feelBadDescription:
      'Das sind die Bereiche, die für dein Arbeitswohlbefinden verbessert werden müssen',
    notEnoughtData1:
      'Wir haben nicht genug Daten, um dir eine genaue Statistik der Woche zu liefern.',
    notEnoughtData2: 'Komm zurück, wenn du mehr Feedback gegeben hast.',
    feedbackDetails: {
      teamLabel: 'Team, dem du Feedback gegeben hast',
    },
  },
  changeTeamBottomSheet: {
    title: 'Wähle ein Team',
    others: 'Oder du kannst sehen:',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    summary: 'Zusammenfassung',
  },
  changeCompanyBottomSheet: {
    title: 'Wähle ein Unternehmen',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    summary: 'Zusammenfassung',
  },
  feedback: {
    title: 'Wie war dein Tag heute?',
    alreadyVoted: 'Team, über das du bereits Feedback gegeben hast',
    anonymous: 'Sichtbarkeit: Anonym',
    named: 'Sichtbarkeit: Namentlich',
    leaveComment: 'Hinterlasse einen Kommentar',
    notEnoughtData:
      'Du kannst nicht antworten, weil du noch keinem Team angehörst.',
    commentPlaceholder: 'Beschreibe deinen Tag in wenigen Worten...',
    feedbackSelectionTypeTitle: 'Welche Aspekte haben deinen Tag beeinflusst?',
    goodAreasFeedback: 'Welche Aspekte waren gut?',
    badAreasFeedback: 'Welche Aspekte müssen verbessert werden?',
    feedbackVisibility: 'Feedback-Sichtbarkeit',
    bottomBar: {
      points: 'Punkte:',
      on: 'an',
      finish: 'Fertig',
    },
  },
  harmonia: {
    emptyMessage:
      'Hallo, ich bin Harmonia, wie war dein Tag? Lass uns darüber sprechen, ich kann dir helfen, ihn besser zu machen!',
    notViewed: 'Neue Nachrichten',
    inputPlaceholder: 'Schreibe eine Nachricht...',
    message1: 'Ich versuche mich an deinen Namen zu erinnern...',
    message2: 'Ich deute deine Feedbacks...',
    message3: 'Ich denke über die beste Antwort für dich nach...',
    message4: 'Ich weiß nicht, vielleicht ist es so besser...',
    message5: 'Gib mir noch einen Moment...',
    connectionError:
      'Wiederherstellung der Verbindung fehlgeschlagen, versuchen Sie, diesen Bildschirm erneut aufzurufen',
  },
  slack: {
    requestScreen: {
      title: 'Füge Qomprendo zu deinem Slack-Arbeitsbereich hinzu',
      paragraph:
        'Fahre fort, um Qomprendo zu autorisieren, mit dir über Slack zu kommunizieren',
      cta: 'Fortfahren mit Slack',
    },
    errorFallback: {
      title: 'Es ist ein Fehler aufgetreten',
      cta: 'Erneut versuchen',
    },
    successFallback: {
      title:
        'Glückwunsch! Du hast Qomprendo gerade zu deinem Arbeitsbereich auf Slack hinzugefügt',
      cta: 'Zurück zur Startseite',
    },
  },
  allChallenges: {
    allResults: 'Alle erzielten Ergebnisse',
    previousChallenges: 'Vergangene Wettbewerbe',
    range: 'Zeitraum',
    prize: 'Preis',
    quantity: 'Menge',
  },
  shared: {
    selectAppLanguage: {
      label: 'Ausgewählte Sprache',
      modalTitle: 'Sprache ändern',
      modalDescription:
        'Wähle die Sprache, die du in der Anwendung verwenden möchtest',
      save: 'Speichern',
      cancel: 'Abbrechen',
    },
    languages: {
      it: 'Italiano',
      en: 'English',
      de: 'Deutsch',
      es: 'Español',
    },
  },
  remoteConfig: {
    versionControl: {
      title: 'Update verfügbar',
      description:
        'Eine neue Version von Qomprendo ist verfügbar, aktualisiere sie, um die App weiterhin nutzen zu können.',
      update: 'Aktualisieren',
    },
    genericError: {
      title: 'Die App ist derzeit nicht verfügbar',
      message:
        'Es scheint ein Problem mit dem Server zu geben, wir entschuldigen uns für die Unannehmlichkeiten, bitte versuche es später noch einmal',
    },
    networkError: {
      title: 'Es gibt Probleme mit deinem Netzwerk',
      description:
        'Wir haben Probleme mit deinem Internetnetzwerk festgestellt, dies verhindert die Nutzung von Qomprendo, bitte versuche es später erneut oder wenn du eine stabilere Verbindung hast',
      ctaLabel: 'Erneut versuchen',
    },
  },
};
