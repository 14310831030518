import jwtDecode from 'jwt-decode';
import { JwtTokenInfo } from '../models/general.models';
import dayjs from 'dayjs';

export default function isTokenExpired(accessToken: string) {
  const tokenInfo = jwtDecode<JwtTokenInfo>(accessToken);
  const expirationDate = dayjs(tokenInfo.exp * 1000);
  const isExpired = expirationDate.isBefore(dayjs());
  return isExpired;
}
