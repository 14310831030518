export default {
  timePicker: {
    title: 'Scegli un orario',
    confirm: 'Conferma',
    cancel: 'Annulla',
  },
  days: {
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
  },
  months: {
    january: 'Gennaio',
    february: 'Febbraio',
    march: 'Marzo',
    april: 'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'Luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novebre',
    december: 'Dicembre',
  },
  bottomTab: {
    teams: 'Team',
    profile: 'Profilo',
    progress: 'Progressi',
    stats: 'Statistiche',
    feedback: 'Feedback',
    teamSettings: 'Gestione team',
  },
  authentication: {
    title: 'Ciao, entra per cominciare!',
    description:
      'Unisciti a noi e inizia a tener traccia del tuo umore quotidiano, o usalo con il tuo team di lavoro!',
    email: 'Email',
    acceptTerms1: 'Dichiaro di accettare ',
    acceptTerms2: ' e ',
    acceptTerms3: ' di Qomprendo',
    termsAndConditions: 'Termini & Codizioni',
    privacyPolicy: 'Privacy Policy',
    emailError: 'La email inserita non è valida',
    passwordlessExplanation:
      'Ti invieremo un codice per verifica la tua identità, inseriscilo e sarai dentro!',
    cta: 'Accedi',
    emailNotFound: 'Email non trovata',
    emailNotFoundDescription:
      'Dovresti essere registrato ma non lo sei? Contatta il supporto a support@qomprendo.com',
  },
  onboarding: {
    privacy: {
      title: 'Privacy, termini e condizioni',
      description:
        'Per poter continuare, è necessario accettare la privacy policy ed infine i termini e condizioni',
      confirm: 'Conferma',
    },
    name: {
      title: 'Il tuo nome',
      description:
        'Inserisci il nome che verrà visualizzato dalle altre persone che utilizzano Qomprendo.',
      name: 'Nome',
      next: 'Avanti',
    },
    birthDate: {
      title: 'La tua data di nascita',
      description:
        'La data di nascita è importante per comprendere meglio il tuo reale umore',
      birthDate: 'Data di nascita',
      confirm: 'Conferma',
      cancel: 'Annulla',
      next: 'Avanti',
      skip: 'Salta',
    },
    anonymousVote: {
      title: 'Visibilità feedback',
      description1:
        'Noi di Qomprendo teniamo moltissimo alla tua privacy, per questo che il feedback è anonimo di default.',
      description3:
        'Questa impostazione è sempre disponibile nel tuo profilo e puoi cambiarla quando vuoi.',
      switchLabel: 'Abilita feedback nominativo',
      skip: 'Salta',
      finish: 'Finito',
    },
  },
  intro: {
    welcome: 'Benvenuto!',
    feedbackTitle: 'Il feedback di cui avevi bisogno',
    feedbackDescription:
      'Condividi il feedback sulla tua giornata con la tua azienda, in modo che possa migliorare e prendere le giuste decisioni!',
    historyTitle: 'Ricordati dei voti che hai dato',
    statsDescription:
      'Tieni traccia del tuo umore su ogni team e capirai subito gli aspetti che vanno meglio e quelli da migliorare!',
    gamificationTitle: 'Guadagna punti e arriva primo',
    rewardsDescription:
      'Partecipa alla human challenge collezionando più punti possibile, aiuta la tua azienda a raggiungere tutti gli obiettivi e avrai la possibilità di vincere un premio!',
    enter: 'Accedi',
  },
  authCode: {
    title: 'Inserisci il codice',
    description: 'Inserisci il codice che ti abbiamo mandato a %{email}.',
    resendCode: 'Rimanda il codice',
    confirm: 'Conferma',
    pasteClipboard: 'Incolla codice da clipboard',
  },
  teamSettings: {
    roleDescription1: 'Sei',
    roleDescription2: 'del team.',
    administrator: 'amministratore',
    teamMembers: 'Membri',
    yourNotifications: 'Notifiche',
    teamNotifications: 'Notifiche standard del team',
    enable: 'Abilita',
    personalizeReminder: 'Personalizza le notifiche',
    emptyDescription: 'Non è ancora presente alcun team da gestire.',
    at: 'Alle:',
  },
  teamMembers: {
    search: 'Cerca...',
    title: 'Membri del team',
  },
  manageUser: {
    title: 'Gestisci utente',
    handledUser: 'Stai gestendo:',
    notifications: 'Notifiche',
    enable: 'Abilita',
    personalizeReminder: 'Personalizza le notifiche',
    at: 'Alle:',
    removeFromTeam: 'Rimuovi dal team',
  },
  userNotification: {
    title: 'Notifiche',
    enablePush: 'Abilita Promemoria Push',
    enableEmail: 'Abilita Promemoria Email',
    at: 'Alle:',
    save: 'Salva',
    cancel: 'Annulla',
  },
  profile: {
    title: 'Profilo',
    details: 'Dettagli',
    changeImage: 'Cambia immagine profilo',
    dateOfBirth: 'Data di nascita',
    username: 'Username',
    email: 'Email',
    signOut: 'Logout',
    requestDelete: 'Richiedi cancellazione',
    editProfileImage: 'Cambia immagine profilo',
    settings: 'Impostazioni',
    anonymousVoteLabel: 'Abilita il feedback anonimo',
    harmoniaActiveLabel: 'Abilita i consigli di Harmonia',
    deleteModal: {
      title: 'Confermi la cancellazione?',
      description:
        "La tua richiesta verrà presa in carico ed il tuo account verrà cancellato in un massimo di 14 giorni dalla richiesta. Una volta cancellato l'account non potrai più accedere e dovrai registrarti nuovamente per poter interagire nuovamente con l'applicazione. Sei ancora sicuro di voler cancellare il tuo profilo?",
      cancel: 'Annulla',
      delete: 'Cancella',
    },
    confirmModal: {
      title: 'Cancellazione richiesta',
      description:
        "Ci dispiace vederti andare via... Entro 14 giorni il tuo profilo verrà eliminato e l'accesso alla tua area privata non sarà più possibile. Ti ringraziamo per averci dato una chance e speriamo, in futuro, di rivederti!",
      ok: 'Ok',
    },
  },
  editProfile: {
    title: 'Modifica profilo',
    description:
      'In questa sezione puoi modificare il tuo profilo, al momento solamente il nome utente può essere modificato.',
    usernamePlaceholder: 'Nome utente',
    usernameError: 'Il nome utente deve contenere almeno 3 caratteri',
    save: 'Salva profilo',
  },
  gamification: {
    feedbackModal: {
      title: 'Complimenti!',
      descriptionNotCompleted:
        "Il tuo albero digitale è cresciuto! Continua così e diventerà grande in un batter d'occhio!",
      descriptionCompleted:
        'Fantastico! Il tuo albero digitale è diventato grande solo grazie a te, da domani ne avrai un altro da crescere. Continua così!',
      close: 'Chiudi',
    },
    myBadge: 'I miei badge',
    section: 'Sezione',
    personal: 'Personale',
    company: 'Azienda',
    companySection: {
      allProgresses: 'Traguardi',
      daysLeft: 'Giorni rimanenti',
      challengeExpired: 'Sfida terminata',
      nextPrice: 'Prossimo premio: %{currentPoints} / %{targetPoints}',
      prizes: 'Premi',
      results: 'Risultati fino ad ora',
      unlockAllPrizes:
        'La tua azienda ha sbloccato tutti i premi, complimenti!',
      quantity: 'Quantità',
      noData:
        'In questo momento non è presente una challenge nella tua azienda.',
    },
  },
  leaderboard: {
    emptyMessage:
      "Qui c'è solo un gatto felice, ma ancora nessuno in classifica.",
    yourPosition: 'La tua posizione',
    leaderboard: 'Classifica',
    firstArrived:
      '🥇🏆 Complimenti! Questo mese sei arrivato 1°! Continua cosi anche il prossimo mese!',
    secondArrived:
      '🥈🏆 Complimenti! Questo mese sei arrivato 2°! Mancava pochissimo per la prima posizione!',
    thirdArrived:
      '🥉🏆 Complimenti! Questo mese sei arrivato 3°! Mancava pochissimo per la prima posizione!',
    parteciped:
      'Grazie per aver partecipato alla competizione! Continua a prenderti cura del tuo benessere in azienda.',
    helpBottomSheet: {
      title: 'Come funziona?',
      pointDescription:
        'Per ogni feedback lasciato su un team vengono assegnati dei punti, il totale punti è aggiornato a fine giornata. Con un feedback si ottengono 100 punti, aggiungendo anche un commento 150.',
      rewardDescription:
        "Se alla fine del mese almeno l'80% dei feedback aziendali sono stati dati, Qomprendo si impegnerà a piantare degli alberi a suo nome.",
      close: 'Chiudi',
    },
  },
  stats: {
    summary: 'Riepilogo',
    daysInRow: 'Costanza',
    humorChart: "Grafico dell'umore",
    moodsCount: "Conteggio stati d'animo",
    commentSentiment: 'Analisi dei commenti',
    moodCalendar: "Il tuo calendario dell'umore",
    wellnessIndex: 'Indice di benessere',
    feedbackCalendar: 'Il tuo calendario dei feedback',
    streak: 'Striscia settimanale più lunga: ',
    forgetFeedback1: 'Ti sei dimenticato', // TODO: Rimuovere
    forgetFeedback2: 'volte di dare il feedback questa settimana', // TODO: Rimuovere
    feedbackCountDescription1:
      'Ehi %{name}, hai espresso %{feedbackCount} feedback negli ultimi 14 giorni,',
    feedbackCountDescription2: {
      lowerThanTwentyPercent:
        'dev’essere un periodo intenso, prenditi cura del tuo benessere, usare Qomprendo può fare la differenza!',
      fromTwentyToForty: 'raccontami com’è andata la tua giornata oggi.',
      fromFortyToSixty:
        'sei partito con il piede giusto, dimmi più spesso come ti senti!',
      fromSixtyToEighty:
        'ottimo lavoro! Stai facendo la differenza per il tuo benessere!',
      fromEightyToHundred: 'Sei vicino alla settima perfetta, continua così!',
      hundred: 'WOW! Hai raggiunto la settimana perfetta, continua così!',
    },
    emptySentiment:
      'Non abbiamo dati a sufficenza per capire il sentimento prevalente della settimana.',
    rememberComment:
      'Ricordati sempre di commentare, in modo da avere questa sezione sempre abilitata.',
    sentimentsComments:
      'I sentimenti che sono emersi dai tuoi commenti sono prevalentemente:',
    positive: 'Positivi',
    negative: 'Critici',
    neutral: 'Neutrali',
    mixed: 'Contrastanti',
    warning: 'Avviso',
    sentimentWarning1: 'Questa settimana hai lasciato un commento solo il',
    sentimentWarning2: 'delle volte.',
    sentimentWarning3:
      'Proteggi il tuo benessere, raccontami come ti senti più spesso.',
    feelGood: 'Aspetti positivi',
    feelGoodDescription:
      'Queste sono le aree che stanno contribuendo al tuo benessere lavorativo',
    feelBad: 'Aspetti da migliorare',
    feelBadDescription:
      'Queste sono le aree da migliorare per il tuo benessere lavorativo',
    notEnoughtData1:
      'Non abbiamo dati a sufficenza per fornirti una statistica precisa della settimana.',
    notEnoughtData2: 'Ripassa quando avrai dato più feedback.',
    feedbackDetails: {
      teamLabel: 'Team di cui hai dato il feedback',
    },
  },
  changeTeamBottomSheet: {
    title: 'Seleziona un team',
    others: 'Oppure puoi vedere:',
    cancel: 'Annulla',
    confirm: 'Conferma',
    summary: 'Riepilogo',
  },
  changeCompanyBottomSheet: {
    title: "Seleziona un'azienda",
    cancel: 'Annulla',
    confirm: 'Conferma',
    summary: 'Riepilogo',
  },
  feedback: {
    title: "Com'è andata oggi?",
    alreadyVoted: 'Team su cui hai già espresso il tuo feedback',
    anonymous: 'Visibilità: Anonimo',
    named: 'Visibilità: Nominativo',
    leaveComment: 'Lascia un commento',
    notEnoughtData:
      'Non puoi rispondere perché non fai ancora parte di un team.',
    commentPlaceholder: 'Descrivi la tua giornata in poche parole...',
    feedbackSelectionTypeTitle:
      'Quali aspetti hanno influenzato la tua giornata?',
    goodAreasFeedback: 'Quali aspetti sono andati bene?',
    badAreasFeedback: 'Quali aspetti sono da migliorare?',
    feedbackVisibility: 'Visibilità feedback',
    bottomBar: {
      points: 'Punti:',
      on: 'su',
      finish: 'Fatto',
    },
  },
  harmonia: {
    emptyMessage:
      "Ciao, sono Harmonia, com'è andata la tua giornata? Parliamone insieme, posso aiutarti a renderla migliore!",
    notViewed: 'Nuovi messaggi',
    inputPlaceholder: 'Scrivi un messaggio...',
    message1: 'Cerco di ricordarmi come ti chiami...',
    message2: 'Sto interpretando i tuoi feedback...',
    message3: 'Sto pensando alla risposta migliore da darti...',
    message4: 'Non so, forse cosi è meglio...',
    message5: 'Dammi ancora un attimino...',
    connectionError:
      'Riconnessione fallita, prova a rientrare in questa schermata',
  },
  slack: {
    requestScreen: {
      title: 'Aggiungi Qomprendo al tuo spazio di lavoro su Slack',
      paragraph:
        'Prosegui per autorizzare Qomprendo a comunicare con te tramite slack',
      cta: 'Continua con Slack',
    },
    errorFallback: {
      title: 'Si è verificato un errore',
      cta: 'Riprova',
    },
    successFallback: {
      title:
        'Complimenti! Hai appena aggiunto Qomprendo alla tua area di lavoro su Slack',
      cta: 'Torna alla homepage',
    },
  },
  allChallenges: {
    allResults: 'Tutti i risultati ottenuti',
    previousChallenges: 'Le competizioni passate',
    range: 'Periodo',
    prize: 'Premio',
    quantity: 'Quantità',
  },
  shared: {
    selectAppLanguage: {
      label: 'Lingua selezionata',
      modalTitle: 'Cambia lingua',
      modalDescription:
        "Seleziona la lingua che vuoi utilizzare sull'applicazione",
      save: 'Salva',
      cancel: 'Annulla',
    },
    languages: {
      it: 'Italiano',
      en: 'English',
      de: 'Deutsch',
      es: 'Español',
    },
  },
  remoteConfig: {
    versionControl: {
      title: 'Aggiornamento disponibile',
      description:
        "Una nuova versione di Qomprendo è disponibile, aggiorna per poter continuare ad usare l'app.",
      update: 'Aggiorna',
    },
    genericError: {
      title: "Al momento l'app non è disponibile",
      message:
        'Pare che ci siano dei problemi con il server, ci scusiamo per il disagio, riprova più tardi',
    },
    networkError: {
      title: 'Ci sono problemi con la tua rete',
      description:
        'Abbiamo riscontrato problemi con la tua rete internet e questo non ti permette di utilizzare Qomprendo, riprova più tardi o quando hai una connessione più stabile',
      ctaLabel: 'Riprova',
    },
  },
};
