import {
  useFonts,
  Rubik_300Light,
  Rubik_400Regular,
  Rubik_500Medium,
  Rubik_600SemiBold,
} from '@expo-google-fonts/rubik';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Asset } from 'expo-asset';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import React, { useEffect, useMemo, useState } from 'react';
import { Animated, Platform, StyleSheet, View } from 'react-native';
import { NotImplementedAuthorizationApi } from '../api/authorization/IAuthorizationApi';
import { useApi } from '../context/ApiContext/useApi';
import { useDispatch, useSelector } from '../redux/utils';
import { StorageKeys } from '../services/StorageProvider/IStorageProvider';
import {
  setIsAuthorized,
  setIsOnboardingFinished,
} from '../redux/general/general.slice';
import {
  useGetMyDetailsMutation,
  useUpdateProfileMutation,
} from '../hooks/api/user.api';
import { LoaderType } from '../hooks/api/utils';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as Localization from 'expo-localization';
import { useLanguage } from '../locale';
import { useStorageProvider } from '../context/StorageProviderContext/useStorageProvider';
import { SupportedLocale } from '../locale/localizationContext';
import useRemoteConfig, { RemoteConfigError } from '../hooks/useRemoteConfig';
import { isAuthorizedSelector } from '../redux/general/general.selectors';
import RemoteConfigScreen from '../screens/remoteConfig/remoteConfig.screen';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const splashImage = require('../../assets/splash.png');

type AnimatedAppLoaderProps = {
  children: React.ReactNode;
};

export const AnimatedAppLoader: React.FC<AnimatedAppLoaderProps> = ({
  children,
}) => {
  const [, setLocale, locale] = useLanguage();
  const storage = useStorageProvider();

  const [fontLoaded] = useFonts({
    Rubik_300Light,
    Rubik_400Regular,
    Rubik_500Medium,
    Rubik_600SemiBold,
  });
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSplashReady, setSplashReady] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const { authApi } = useApi();
  const { mutateAsync: getUserDetails } = useGetMyDetailsMutation(
    LoaderType.None
  );

  const [{ regionCode, languageCode }] = Localization.useLocales();
  const [{ timeZone }] = Localization.getCalendars();
  const { mutateAsync: updateCountryAndLanguage } = useUpdateProfileMutation();

  const isApiReady = !(authApi instanceof NotImplementedAuthorizationApi);
  const isAuthorized = useSelector(isAuthorizedSelector);

  const { error, checkCompleted, canContinue } = useRemoteConfig();

  useEffect(() => {
    const run = async () => {
      try {
        if (Platform.OS !== 'web') {
          await ScreenOrientation.lockAsync(
            ScreenOrientation.OrientationLock.PORTRAIT_UP
          );
        }

        const storageLocale = await storage.getItem(
          StorageKeys.SELECTED_LANGUAGE
        );

        if (storageLocale) {
          setLocale(storageLocale as SupportedLocale);
        } else {
          const [deviceLocale] = Localization.getLocales();
          let selectedLang: SupportedLocale = SupportedLocale.En;
          if (
            deviceLocale.languageCode &&
            (deviceLocale.languageCode === SupportedLocale.It ||
              deviceLocale.languageCode === SupportedLocale.De ||
              deviceLocale.languageCode === SupportedLocale.Es)
          ) {
            selectedLang = deviceLocale.languageCode as SupportedLocale;
          }

          setLocale(selectedLang);
          await storage.setItem(StorageKeys.SELECTED_LANGUAGE, selectedLang);
        }

        await Asset.fromModule(splashImage).downloadAsync();
      } catch (e) {
      } finally {
        setIsInitialized(true);
      }
    };
    run();
  }, [storage, setLocale]);

  useEffect(() => {
    const prepare = async () => {
      if (isApiReady && canContinue) {
        try {
          const accessToken = await AsyncStorage.getItem(
            StorageKeys.ACCESS_TOKEN_KEY
          );
          if (accessToken) {
            dispatch(setIsAuthorized(true));
            const userDetails = await getUserDetails();
            if (userDetails.terms) {
              dispatch(setIsOnboardingFinished(true));
            }
          }
        } catch (e) {
        } finally {
          setSplashReady(true);
        }
      } else if (checkCompleted) {
        setSplashReady(true);
      }
    };

    prepare();
  }, [
    dispatch,
    getUserDetails,
    isApiReady,
    canContinue,
    setLocale,
    storage,
    checkCompleted,
  ]);

  useEffect(() => {
    const checkReady = async () => {
      if (
        fontLoaded &&
        isInitialized &&
        isApiReady &&
        isSplashReady &&
        checkCompleted
      ) {
        setIsAppReady(true);
        await SplashScreen.hideAsync();
      }
    };

    checkReady();
  }, [
    fontLoaded,
    isAppReady,
    isSplashReady,
    checkCompleted,
    isApiReady,
    isInitialized,
    error,
  ]);

  useEffect(() => {
    const init = async () => {
      if (isAuthorized && canContinue) {
        await updateCountryAndLanguage({
          country: regionCode ?? undefined,
          lang: locale || languageCode,
          timezone: timeZone ?? undefined,
        });
      }
    };
    init();
  }, [
    isAuthorized,
    languageCode,
    timeZone,
    regionCode,
    updateCountryAndLanguage,
    canContinue,
    locale,
  ]);

  return (
    <AnimatedSplashScreen error={error} isAppReady={isAppReady}>
      {isAppReady ? children : null}
    </AnimatedSplashScreen>
  );
};

type AnimatedSplashScreenProps = {
  error?: RemoteConfigError;
  children: React.ReactNode;
  isAppReady?: boolean;
};

const AnimatedSplashScreen: React.FC<AnimatedSplashScreenProps> = ({
  children,
  error,
  isAppReady,
}) => {
  const animation = useMemo(() => new Animated.Value(1), []);
  const [isSplashAnimationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    if (isAppReady) {
      Animated.timing(animation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => setAnimationComplete(true));
    }
  }, [isAppReady]);

  return (
    <View style={{ flex: 1 }}>
      {error ? <RemoteConfigScreen error={error} /> : children}
      {!isSplashAnimationComplete && (
        <Animated.View
          pointerEvents="none"
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: Constants.expoConfig!.splash!.backgroundColor,
              opacity: animation,
            },
          ]}
        >
          <Animated.Image
            style={{
              width: '100%',
              height: '100%',
              resizeMode: Constants.expoConfig!.splash!.resizeMode || 'contain',
              transform: [
                {
                  scale: animation,
                },
              ],
            }}
            source={splashImage}
            fadeDuration={300}
          />
        </Animated.View>
      )}
    </View>
  );
};
