import * as React from 'react';
import { G, Path, Defs, ClipPath, Svg } from 'react-native-svg';
import { IconProps } from './types';

type MessageBadgeIconProps = Omit<IconProps, 'color'>;

const MessageBadgeIcon: React.FC<MessageBadgeIconProps> = ({
  height,
  width,
  size,
}) => {
  const heightSize = size && size * 0.8;

  return (
    <Svg
      width={width || size || 15}
      viewBox="0 0 15 12"
      height={height || heightSize || 12}
      fill="none"
    >
      <G clipPath="url(#a)">
        <Path
          fill="#E0E0E0"
          fillRule="evenodd"
          d="M6.402 10.94c-.83 0-1.622-.124-2.35-.35-.743.58-1.995 1.384-3.217 1.384.64-.606.821-1.78.86-2.654C.644 8.423 0 7.225 0 5.91 0 3.13 2.866.878 6.402.878s6.403 2.252 6.403 5.03c0 2.779-2.867 5.03-6.403 5.03Z"
          clipRule="evenodd"
        />
        <Path
          fill="#F4F5F7"
          fillRule="evenodd"
          d="M8.415 10.061a7.94 7.94 0 0 0 2.35-.35c.743.58 1.995 1.384 3.217 1.384-.64-.606-.821-1.78-.86-2.654 1.052-.897 1.695-2.095 1.695-3.41C14.817 2.251 11.951 0 8.415 0S2.012 2.252 2.012 5.03c0 2.779 2.867 5.031 6.403 5.031Z"
          clipRule="evenodd"
        />
        <Path
          fill="#E0E0E0"
          d="M5.195 6.44a.878.878 0 1 0 0-1.756.878.878 0 0 0 0 1.756ZM8.415 6.44a.878.878 0 1 0 0-1.756.878.878 0 0 0 0 1.756ZM11.634 6.44a.878.878 0 1 0 0-1.756.878.878 0 0 0 0 1.756Z"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h14.817v12H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default MessageBadgeIcon;
