import { s } from 'react-native-wind';
import * as React from 'react';
import { GenericScreenProps } from '../../navigation/core/types';
import {
  BottomSheetRef,
  CrownIcon,
  HomeScreenHeader,
  Layout,
  Paragraph,
  ParagraphBold,
  Separator,
  Subtitle,
} from '../../components';
import { useGetMyCompanies } from '../../hooks/api/company.api';
import {
  Dimensions,
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Config } from '../../config';
import {
  useGetCompanyLeaderboard,
  useGetMyCompanyLeaderboardPosition,
} from '../../hooks/api/gamification.api';
import { useGetMyDetails } from '../../hooks/api/user.api';
import { ProfileImage } from '../../components/ProfileImage';
import CatEmptyStateImage from '../../../icons/CatEmptyStateImage';
import { useLanguage } from '../../locale';
import { LeaderboardPosition } from './components/LeaderboardPosition';
import { LeaderboardElement } from '../../api/gamification/gamification.models';
import { GamificationHelpBottomSheet } from './components/GamificationHelpBottomSheet';
import { ArrowsRightLeftIcon } from 'react-native-heroicons/outline';
import { ChangeCompanyBottomSheet } from '../../components/ChangeCompanyBottomSheet';
import { useDispatch, useSelector } from '../../redux/utils';
import { selectedCompanySelector } from '../../redux/general/general.selectors';
import { setSelectedCompany } from '../../redux/general/general.slice';
import dayjs from 'dayjs';
import { default as ConfettiCannon } from 'react-native-confetti-cannon';
import { getLeaderboardMessageTranslation } from '../../utils/getLeaderboardMessageTranslation';
import { useBackNavbarAction } from '../../hooks';
import { LEADERBOARD_SCREEN } from '../../navigation/authenticated/authenticated.types';

type LeaderboardScreenProps = {} & GenericScreenProps<
  typeof LEADERBOARD_SCREEN
>;

export const LeaderboardScreen: React.FC<LeaderboardScreenProps> = () => {
  const [t] = useLanguage();
  const dispatch = useDispatch();
  const confettiCannonRef1 = React.useRef<ConfettiCannon>(null);
  const confettiCannonRef2 = React.useRef<ConfettiCannon>(null);
  const helpBottomSheetRef = React.useRef<BottomSheetRef>(null);
  const changeCompanyBottomSheetRef = React.useRef<BottomSheetRef>(null);
  const selectedCompany = useSelector(selectedCompanySelector);
  const [restOfLeaderboard, setRestOfLeaderboard] = React.useState<
    LeaderboardElement[]
  >([]);
  const backAction = useBackNavbarAction();

  const leaderboardDate = React.useMemo(() => {
    return dayjs().subtract(1, 'day');
  }, []);

  const isFirstOfTheMonth = React.useMemo(() => {
    return dayjs().date() === 1;
  }, []);

  const leaderboardMonth = leaderboardDate.format('YYYY-MM');

  const { data: myCompanies } = useGetMyCompanies(false);
  const { data: myDetails } = useGetMyDetails();
  const { data: companyLeaderboard } = useGetCompanyLeaderboard(
    selectedCompany?.id,
    leaderboardMonth
  );
  const { data: myPositionInCompany } = useGetMyCompanyLeaderboardPosition(
    selectedCompany?.id,
    leaderboardMonth
  );

  const firstOfTheMonthMessage = React.useMemo(() => {
    if (isFirstOfTheMonth && myPositionInCompany?.position !== undefined) {
      return getLeaderboardMessageTranslation(t, myPositionInCompany?.position);
    }
  }, [isFirstOfTheMonth, myPositionInCompany?.position, t]);

  const orderedCompanies = React.useMemo(() => {
    return myCompanies?.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }, [myCompanies]);

  React.useEffect(() => {
    if (!selectedCompany && orderedCompanies && orderedCompanies.length > 0) {
      dispatch(setSelectedCompany(orderedCompanies[0]));
    }
  }, [dispatch, orderedCompanies, selectedCompany]);

  React.useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (
      isFirstOfTheMonth &&
      companyLeaderboard &&
      companyLeaderboard?.length > 0
    ) {
      timeoutRef = setTimeout(() => {
        confettiCannonRef1.current?.start();
        confettiCannonRef2.current?.start();
      }, 1 * 1000);
    }

    return () => clearTimeout(timeoutRef);
  }, [companyLeaderboard, isFirstOfTheMonth]);

  React.useEffect(() => {
    if (companyLeaderboard && companyLeaderboard.length > 3) {
      const leaderboardCopy = [...companyLeaderboard];
      leaderboardCopy.splice(0, 3);
      setRestOfLeaderboard(leaderboardCopy);
    } else {
      setRestOfLeaderboard([]);
    }
  }, [companyLeaderboard]);

  const openChangeCompanyBottomSheet = React.useCallback(() => {
    changeCompanyBottomSheetRef.current?.expand();
  }, []);

  const customHeader = React.useMemo(() => {
    return (
      <HomeScreenHeader
        horizontalPadding={false}
        title={selectedCompany?.name || ''}
        rightIcon={
          orderedCompanies && orderedCompanies.length > 1 ? (
            <TouchableOpacity onPress={openChangeCompanyBottomSheet}>
              <ArrowsRightLeftIcon color={Config.colorTextSecondary} />
            </TouchableOpacity>
          ) : undefined
        }
      />
    );
  }, [openChangeCompanyBottomSheet, orderedCompanies, selectedCompany?.name]);

  return (
    <>
      <Layout
        containerStyle={s`py-0`}
        isScrollView={Platform.OS === 'web'}
        showNavbar={true}
        leftAction={backAction}
      >
        {customHeader}
        {companyLeaderboard && companyLeaderboard.length > 0 ? (
          <>
            <FlatList
              style={s`flex-1`}
              showsVerticalScrollIndicator={false}
              ListFooterComponent={<View style={s`mt-6`} />}
              data={restOfLeaderboard}
              keyExtractor={(e) => `${e.pk}-${e.user.username}`}
              ListHeaderComponent={
                <>
                  {isFirstOfTheMonth && firstOfTheMonthMessage ? (
                    <View
                      style={s`mt-4 rounded-2xl bg-primary-light p-4 border border-primary`}
                    >
                      <Paragraph>{firstOfTheMonthMessage}</Paragraph>
                    </View>
                  ) : null}
                  <ParagraphBold style={s`text-center mt-8 text-2xl mb-1`}>
                    1
                  </ParagraphBold>
                  <View style={s`self-center mb-2`}>
                    <CrownIcon size={36} />
                  </View>
                  <View style={s`w-36 self-center`}>
                    {companyLeaderboard?.length >= 2 ? (
                      <View
                        style={[s`justify-center`, styles.secondPlaceContainer]}
                      >
                        <ParagraphBold style={s`text-center text-2xl mb-3`}>
                          2
                        </ParagraphBold>
                        <View style={[s`rounded-full`]}>
                          <ProfileImage
                            placeholderStyle={s`border-4 border-primary`}
                            imageStyle={s`border-4 border-primary`}
                            userId={companyLeaderboard[1].userId}
                            username={companyLeaderboard[1].user.username}
                          />
                        </View>
                        <View style={s`self-center mt-2`}>
                          <Paragraph
                            numberOfLines={1}
                            style={s`text-center text-xs mb-1`}
                          >
                            {companyLeaderboard[1].user.username}
                          </Paragraph>
                          <ParagraphBold
                            numberOfLines={1}
                            style={s`text-center text-xl mb-1 text-primary`}
                          >
                            {companyLeaderboard[1].points.toString()}
                          </ParagraphBold>
                        </View>
                      </View>
                    ) : null}
                    {companyLeaderboard?.length >= 3 ? (
                      <View
                        style={[s`justify-center`, styles.thirdPlaceContainer]}
                      >
                        <ParagraphBold style={s`text-center text-2xl mb-3`}>
                          3
                        </ParagraphBold>
                        <View style={[s`rounded-full`]}>
                          <ProfileImage
                            placeholderStyle={s`border-4 border-primary`}
                            imageStyle={s`border-4 border-primary`}
                            userId={companyLeaderboard[2].userId}
                            username={companyLeaderboard[2].user.username}
                          />
                        </View>
                        <View style={s`self-center mt-2`}>
                          <Paragraph style={s`text-center text-xs mb-1`}>
                            {companyLeaderboard[2].user.username}
                          </Paragraph>
                          <ParagraphBold
                            style={s`text-center text-xl mb-1 text-primary`}
                          >
                            {companyLeaderboard[2].points.toString()}
                          </ParagraphBold>
                        </View>
                      </View>
                    ) : null}

                    <View style={[s`rounded-full`, styles.imageShadow]}>
                      <ProfileImage
                        placeholderStyle={s`border-4 border-primary`}
                        imageStyle={s`border-4 border-primary`}
                        userId={companyLeaderboard[0].userId}
                        username={companyLeaderboard[0].user.username}
                      />
                    </View>
                    <View style={s`self-center mt-2`}>
                      <Paragraph style={s`text-center text-xs mb-1`}>
                        {companyLeaderboard[0].user.username}
                      </Paragraph>
                      <ParagraphBold
                        style={s`text-center text-xl mb-1 text-primary`}
                      >
                        {companyLeaderboard[0].points.toString()}
                      </ParagraphBold>
                    </View>
                  </View>
                  <View style={s`mt-12`} />
                  {myPositionInCompany && myDetails ? (
                    <>
                      <Subtitle>{t('leaderboard.yourPosition')}</Subtitle>
                      <View style={s`mt-4`}>
                        <LeaderboardPosition
                          points={myPositionInCompany.points}
                          position={
                            myPositionInCompany.position < 1
                              ? 'N.D.'
                              : myPositionInCompany.position
                          }
                          userId={myPositionInCompany.userId}
                          username={myDetails.username}
                        />
                      </View>
                      <Separator style={s`mt-6`} />
                    </>
                  ) : null}
                  <Subtitle style={s`mt-6`}>
                    {t('leaderboard.leaderboard')}
                  </Subtitle>
                </>
              }
              renderItem={(e) => (
                <View style={s`mt-4`}>
                  <LeaderboardPosition
                    points={e.item.points}
                    position={e.index + 4}
                    userId={e.item.userId}
                    username={e.item.user.username}
                  />
                </View>
              )}
              ListEmptyComponent={
                <View style={s`mt-4`}>
                  <CatEmptyStateImage height={200} width={'100%'} />
                  <Paragraph style={s`text-center mt-4`}>
                    {t('leaderboard.emptyMessage')}
                  </Paragraph>
                </View>
              }
            />
          </>
        ) : (
          <View style={s`flex-1 items-center justify-center`}>
            <CatEmptyStateImage height={200} width={'100%'} />
            <Paragraph style={s`text-center mt-4`}>
              {t('leaderboard.emptyMessage')}
            </Paragraph>
          </View>
        )}
      </Layout>
      <GamificationHelpBottomSheet ref={helpBottomSheetRef} />
      <ChangeCompanyBottomSheet ref={changeCompanyBottomSheetRef} />
      <ConfettiCannon
        ref={confettiCannonRef1}
        count={25}
        origin={{ x: -20, y: -20 }}
        autoStart={false}
        explosionSpeed={500}
        fadeOut={true}
      />
      <ConfettiCannon
        ref={confettiCannonRef2}
        count={25}
        origin={{ x: Dimensions.get('window').width + 20, y: -20 }}
        autoStart={false}
        explosionSpeed={500}
        fadeOut={true}
      />
    </>
  );
};

const styles = StyleSheet.create({
  noPagePadding: {
    marginHorizontal: -Config.pagePadding,
  },
  secondPlaceContainer: {
    position: 'absolute',
    top: '10%',
    width: '75%',
    left: '-57%',
  },
  thirdPlaceContainer: {
    position: 'absolute',
    top: '10%',
    width: '75%',
    right: '-57%',
  },
  imageShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,

    elevation: Platform.OS === 'android' ? 8 : undefined,
  },
});
