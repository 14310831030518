import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { useFullPageLoader } from '../../../context/Loader/useFullPageLoader';
import { useSignInMutation } from '../../../hooks/api/auth.api';
import { useGetWhoAmI } from '../../../hooks/api/user.api';
import {
  AUTHENTICATION_SCREEN,
  AUTH_CODE_SCREEN,
} from '../../../navigation/authentication/authentication.types';
import { AppNavigation } from '../../../navigation/core/types';
import { useAuthenticationForm } from './useAuthenticationForm';
import { Alert } from 'react-native';
import { useLanguage } from '../../../locale';

export const useAuthenticationController = () => {
  const [t] = useLanguage();
  const navigation =
    useNavigation<AppNavigation<typeof AUTHENTICATION_SCREEN>>();
  const [openLoader, closeLoader] = useFullPageLoader();
  const { mutateAsync: getWhoAmI } = useGetWhoAmI();
  const { mutateAsync: signInApiCall } = useSignInMutation();

  const { emailValue, onEmailChange, errors, handleSubmit } =
    useAuthenticationForm();

  const signIn = useCallback(
    async (email: string) => {
      return await signInApiCall({ email });
    },
    [signInApiCall]
  );

  const signUpIn = useMemo(() => {
    return handleSubmit(async ({ email }) => {
      const lowerCaseEmail = email.toLocaleLowerCase();
      openLoader();
      try {
        const { exists } = await getWhoAmI(lowerCaseEmail);
        if (!exists) {
          Alert.alert(
            t('authentication.emailNotFound'),
            t('authentication.emailNotFoundDescription'),
            [{ text: 'Ok', style: 'default' }]
          );
        } else {
          const res = await signIn(lowerCaseEmail);
          navigation.push(AUTH_CODE_SCREEN, {
            email: lowerCaseEmail,
            session: res.Session,
          });
        }
      } catch (e) {
      } finally {
        closeLoader();
      }
    });
  }, [closeLoader, getWhoAmI, handleSubmit, navigation, openLoader, signIn, t]);

  return {
    emailValue,
    onEmailChange,
    errors,
    signUpIn,
  };
};
