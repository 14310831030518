import * as React from 'react';
import { s } from 'react-native-wind';
import { Separator, Subtitle } from '../../../components';
import { useLanguage } from '../../../locale';
import dayjs from 'dayjs';
import { AreaChart } from '../../../portability/components/AreaChart/AreaChart';
import { Grid } from '../../../portability/components/Grid/Grid';
import { XAxis } from '../../../portability/components/XAxis/XAxis';
import * as scale from 'd3-scale';
import { View } from 'react-native';
import Decorator from './lineChart/Decorator';
import Gradient from './lineChart/Gradient';
import Line from './lineChart/Line';
import YLabel from './lineChart/YLabel';
import { Config } from '../../../config';
import { useFeedbackLineChartSectionController } from '../controllers/useFeedbackLineChartSectionController';
import { Feedback } from '../../../api/feedback/feedback.models';
import Tooltip from './lineChart/Tooltip';

const xAxisHeight = 30;

type FeedbackLineChartSectionProps = {
  feedbacks: Feedback[] | undefined;
};

const FeedbackLineChartSection: React.FC<FeedbackLineChartSectionProps> = ({
  feedbacks,
}) => {
  const [t] = useLanguage();

  const {
    expandedFeedbackList,
    normalizedFeedbackList,
    panResponder,
    positionX,
  } = useFeedbackLineChartSectionController(feedbacks);

  return (
    <>
      <Subtitle style={s`mb-4`}>{t('stats.humorChart')}</Subtitle>
      {normalizedFeedbackList && (
        <View style={s`h-64 flex-row`}>
          <View
            style={[
              s`flex-col h-full justify-between`,
              { paddingBottom: xAxisHeight },
            ]}
          >
            <YLabel value={4} />
            <YLabel value={3} />
            <YLabel value={2} />
            <YLabel value={1} />
            <View style={s`w-4 h-5`} />
          </View>
          <View
            style={{ flex: 1, marginLeft: 10 }}
            {...panResponder.panHandlers}
          >
            <AreaChart
              style={s`flex-1`}
              data={normalizedFeedbackList}
              contentInset={{ top: 10, bottom: 10 }}
              yMin={0}
              yMax={4}
              animate
              animationDuration={250}
              numberOfTicks={4}
              svg={{ fill: 'url(#gradient)' }}
              yAccessor={({ item }) => item.value}
              xScale={scale.scaleLinear}
            >
              <Grid key="grid" />
              <Gradient key="gradient" />
              <Line key="line" />
              <Decorator key="decorator" />
              <Tooltip
                key="tooltip"
                positionX={positionX}
                list={normalizedFeedbackList}
              />
            </AreaChart>
            <XAxis
              style={{ marginHorizontal: -10, height: xAxisHeight }}
              data={normalizedFeedbackList}
              formatLabel={(_, i) =>
                i % 2
                  ? ''
                  : dayjs(expandedFeedbackList[i].label)
                      .format('DD')
                      .toUpperCase()
              }
              contentInset={{ left: 10, right: 10 }}
              svg={{
                fill: Config.colorTextPrimary,
                fontFamily: Config.fontLight,
                fontSize: 14,
                originY: 15,
                y: 3,
              }}
            />
          </View>
        </View>
      )}
      <Separator style={s`my-6`} />
    </>
  );
};

export default FeedbackLineChartSection;
