import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
import { IconProps } from './types';

type SettingsFilledIconProps = IconProps & SvgProps;

const SettingsFilledIcon = ({
  width,
  height,
  size,
  ...props
}: SettingsFilledIconProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <G clipPath="url(#settings-filled-clip)">
      <Path
        fill="#6AC982"
        d="M464.866 192.8h-10.87a206.755 206.755 0 0 0-13.323-32.097l7.698-7.698c18.665-18.645 18.165-48.525.006-66.665l-22.711-22.71c-18.129-18.153-48.015-18.679-66.665-.007l-7.704 7.704A206.893 206.893 0 0 0 319.2 58.004V47.133C319.2 21.144 298.056 0 272.066 0h-32.133C213.944 0 192.8 21.144 192.8 47.133v10.871a206.698 206.698 0 0 0-32.097 13.323l-7.697-7.697c-18.613-18.635-48.498-18.198-66.664-.006L63.629 86.335c-18.152 18.132-18.677 48.014-.006 66.665l7.704 7.704a206.714 206.714 0 0 0-13.323 32.097h-10.87C21.145 192.8 0 213.944 0 239.933v32.134C0 298.056 21.145 319.2 47.134 319.2h10.87a206.755 206.755 0 0 0 13.323 32.097l-7.698 7.698c-18.665 18.645-18.165 48.525-.006 66.665l22.711 22.71c18.129 18.153 48.015 18.679 66.665.007l7.704-7.704a206.893 206.893 0 0 0 32.097 13.323v10.871c0 25.989 21.145 47.133 47.134 47.133h32.133c25.989 0 47.134-21.144 47.134-47.133v-10.871a206.698 206.698 0 0 0 32.097-13.323l7.697 7.697c18.613 18.635 48.497 18.198 66.664.006l22.713-22.712c18.152-18.132 18.677-48.014.006-66.665l-7.704-7.704a206.714 206.714 0 0 0 13.323-32.097h10.87c25.989 0 47.134-21.144 47.134-47.133v-32.134C512 213.944 490.855 192.8 464.866 192.8ZM256 367.4c-61.427 0-111.4-49.974-111.4-111.4 0-61.426 49.973-111.4 111.4-111.4 61.427 0 111.4 49.974 111.4 111.4 0 61.426-49.973 111.4-111.4 111.4Z"
      />
      <Path
        fill="#5CAB70"
        d="M464.866 192.8h-10.87a206.755 206.755 0 0 0-13.323-32.097l7.698-7.698c18.665-18.645 18.165-48.525.006-66.665l-22.711-22.71c-18.129-18.153-48.015-18.679-66.665-.007l-7.704 7.704A206.893 206.893 0 0 0 319.2 58.004V47.133C319.2 21.144 298.056 0 272.066 0H256v144.6c61.427 0 111.4 49.974 111.4 111.4 0 61.426-49.973 111.4-111.4 111.4V512h16.066c25.989 0 47.134-21.144 47.134-47.133v-10.871a206.698 206.698 0 0 0 32.097-13.323l7.697 7.697c18.613 18.635 48.498 18.198 66.664.006l22.713-22.712c18.152-18.132 18.677-48.014.006-66.665l-7.704-7.704a206.714 206.714 0 0 0 13.323-32.097h10.87c25.989 0 47.134-21.144 47.134-47.133v-32.134c0-25.987-21.145-47.131-47.134-47.131Z"
      />
    </G>
    <Defs>
      <ClipPath id="settings-filled-clip">
        <Path fill="#fff" d="M0 0h512v512H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SettingsFilledIcon;
