import { s } from 'react-native-wind';
import * as React from 'react';
import { GenericScreenProps } from '../../navigation/core/types';
import { USER_NOTIFICATIONS_SCREEN } from '../../navigation/authenticated/authenticated.types';
import {
  Checkbox,
  ChooseVotingDays,
  Layout,
  Paragraph,
  ParagraphBold,
} from '../../components';
import { TouchableOpacity, View } from 'react-native';
import { useBackNavbarAction } from '../../hooks';
import { useLanguage } from '../../locale';
import { VotingDay } from '../../api/team/team.models';
import { useUpdateTeamMemberMeta } from '../../hooks/api/useUpdateTeamMemberMeta';
import { useGetTeamMember } from '../../hooks/api/team.api';
import { updateVotingDaysWithNewValue } from '../../utils/miscellaneous';
import { UmTimePicker } from '../../components/UmTimePicker';
import * as Haptics from '../../portability/services/Haptics/Haptics';
import { UmUserNotificationsBottomBar } from './components/UmUserNotificationsBottomBar';
import dayjs from 'dayjs';

type UserNotificationsScreenProps = {} & GenericScreenProps<
  typeof USER_NOTIFICATIONS_SCREEN
>;

export const UserNotificationsScreen: React.FC<
  UserNotificationsScreenProps
> = ({ navigation, route }) => {
  const [t] = useLanguage();
  const { teamId, userId } = route.params;
  const backIcon = useBackNavbarAction();

  const [selectedWorkingDays, setSelectedWorkingDays] =
    React.useState<VotingDay[]>();
  const [areNotificationsEnabled, setAreNotificationsEnabled] =
    React.useState<boolean>();
  const [notificationEmailEnabled, setNotificationEmailEnabled] =
    React.useState<boolean>(false);
  const [notificationTime, setNotificationTime] = React.useState<string>();
  const [isTimePickerOpen, setIsTimePickerOpen] =
    React.useState<boolean>(false);

  const { mutateAsync: updateTeamMember } = useUpdateTeamMemberMeta();
  const { data: userToEdit } = useGetTeamMember({ teamId, memberId: userId });

  const timePickerStartingDate = React.useMemo(() => {
    if (!userToEdit) return;
    const [hours, minutes] = userToEdit.notificationTime.split(':');
    return dayjs().set('minute', +minutes).set('hour', +hours).toDate();
  }, [userToEdit]);

  React.useEffect(() => {
    if (userToEdit) {
      setSelectedWorkingDays(userToEdit.votingDays);
      setAreNotificationsEnabled(userToEdit.notificationEnabled);
      setNotificationTime(userToEdit.notificationTime);
      // Initialize email notification state if it exists in user data
      if ('notificationEmailEnabled' in userToEdit) {
        setNotificationEmailEnabled(!!userToEdit.notificationEmailEnabled);
      }
    }
  }, [userToEdit]);

  const onCustomRuleWorkingDaysChange = React.useCallback(
    (votingDay: VotingDay) => {
      if (!selectedWorkingDays) return;
      const daysToUpdate = updateVotingDaysWithNewValue(
        votingDay,
        selectedWorkingDays
      );
      setSelectedWorkingDays(daysToUpdate);
    },
    [selectedWorkingDays]
  );

  const saveUpdates = React.useCallback(async () => {
    if (userToEdit) {
      await updateTeamMember({
        memberId: userToEdit.userId,
        teamId,
        notificationTime:
          notificationTime?.slice(0, 5) ?? userToEdit.notificationTime,
        workingDays: selectedWorkingDays!,
        notificationEnabled: areNotificationsEnabled,
        useDefaultVotingDays: false, // Always customized
        notificationEmailEnabled: notificationEmailEnabled,
      });
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      navigation.goBack();
    }
  }, [
    areNotificationsEnabled,
    navigation,
    notificationTime,
    notificationEmailEnabled,
    selectedWorkingDays,
    teamId,
    updateTeamMember,
    userToEdit,
  ]);

  const cancel = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const openTimePicker = React.useCallback(() => {
    setIsTimePickerOpen(true);
  }, []);

  const closeTimePicker = React.useCallback(() => {
    setIsTimePickerOpen(false);
  }, []);

  const confirmNotificationTime = React.useCallback(
    (newTime: string) => {
      closeTimePicker();
      setNotificationTime(newTime);
    },
    [closeTimePicker]
  );

  return (
    <>
      <Layout
        bottomBar={
          <UmUserNotificationsBottomBar
            onCancelPress={cancel}
            onSavePress={saveUpdates}
          />
        }
        title={t('userNotification.title')}
        leftAction={backIcon}
      >
        {areNotificationsEnabled !== undefined ? (
          <View style={s`mt-4 flex-row items-center`}>
            <Checkbox
              isChecked={areNotificationsEnabled}
              onCheckboxPress={() => setAreNotificationsEnabled((pv) => !pv)}
            />
            <Paragraph style={s`ml-4 flex-1`}>
              {t('userNotification.enablePush')}
            </Paragraph>
          </View>
        ) : null}
        {notificationEmailEnabled !== undefined ? (
          <View style={s`mt-4 flex-row items-center`}>
            <Checkbox
              isChecked={notificationEmailEnabled}
              onCheckboxPress={() => setNotificationEmailEnabled((pv) => !pv)}
            />
            <Paragraph style={s`ml-4 flex-1`}>
              {t('userNotification.enableEmail')}
            </Paragraph>
          </View>
        ) : null}
        {(areNotificationsEnabled || notificationEmailEnabled) &&
        notificationTime ? (
          <View style={s`mt-4 flex-row items-center`}>
            <Paragraph style={s`mr-4`}>{t('teamSettings.at')}</Paragraph>
            <TouchableOpacity
              onPress={openTimePicker}
              style={s`w-20 bg-card rounded-md items-center py-1`}
            >
              <ParagraphBold>{notificationTime.slice(0, 5)}</ParagraphBold>
            </TouchableOpacity>
          </View>
        ) : null}
        {(areNotificationsEnabled || notificationEmailEnabled) &&
        selectedWorkingDays ? (
          <View style={s`mt-3`}>
            <ChooseVotingDays
              votingDays={selectedWorkingDays}
              onVotingDayPressed={onCustomRuleWorkingDaysChange}
            />
          </View>
        ) : null}
      </Layout>
      {userToEdit ? (
        <UmTimePicker
          startingDate={timePickerStartingDate}
          onConfirm={confirmNotificationTime}
          onCancel={closeTimePicker}
          isOpen={isTimePickerOpen}
        />
      ) : null}
    </>
  );
};
